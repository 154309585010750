import React from "react";
import Layout from '../components/layout'
import Hero from "../components/hero";
import HeroImageHr from '../images/rsvp-hr.jpg';
import HeroImage from "../images/rsvp-lr.jpg";
import './rsvp.scss'

const Loader = (props) => (
  <div className="flex items-end justify-center">
    {props.children}
    <div id="container">
      <div id="ball-1" class="circle"/>
      <div id="ball-2" class="circle"/>
      <div id="ball-3" class="circle"/>
    </div>
  </div>
)

const FormSection = (props) => {
  return (
    <div className="guest-form-item">
      <div className="fancy-input mb-5">
        <label htmlFor={`name-${props.index}`}>
          <div className="label-text">Name</div>
          <input id={`name-${props.index}`} type="text" autoComplete="off" placeholder="Guest Name" required={props.index === 0} value={props.guest.name} onChange={(e) => props.handleChange(props.index, 'name', e.target.value)}/>
        </label>
      </div>
      <div className="mb-1">
        Will be attending?
      </div>
      <label className="container" htmlFor={`attending-yes-${props.index}`}>
        Yes
        <input name={`attending-${props.index}`} required={true} type="radio" id={`attending-yes-${props.index}`} value="yes" checked={props.guest.attending === "yes"} onChange={() => props.handleChange(props.index, 'attending', 'yes')}/>
        <span className="checkmark" />
      </label>
      <label className="container" htmlFor={`attending-no-${props.index}`}>
        No
        <input name={`attending-${props.index}`} type="radio" id={`attending-no-${props.index}`} value="no" checked={props.guest.attending === "no"} onChange={() => props.handleChange(props.index, 'attending', 'no')}/>
        <span className="checkmark" />
      </label>
      <div className="fancy-input mt-5">
        <label htmlFor={`dietary-${props.index}`}>
          <div className="label-text">
            Dietary Restrictions
          </div>
          <textarea id={`dietary-${props.index}`} type="text" autoComplete="off" value={props.guest.dietary} onChange={(e) => props.handleChange(props.index, 'dietary', e.target.value)}/>
        </label>
      </div>
    </div>
  )
}

export default class RSVP extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      guests: [],
      searchValue: '',
      searchMatches: [],
      hasSearched: false,
      hasSubmitted: false,
      error: false,
      posting: false,
    }
    this.allGuests = {}
  }

  componentDidMount() {
    fetch('https://s3.us-east-2.amazonaws.com/frank-and-krystal-wedding/guests.json').then((r) => r.json()).then((r) => this.allGuests = r)
  }

  handleChangeSearch = (value) => {
    this.setState({searchValue: value})
  }

  handleSearch = (e) => {
    e.preventDefault()
    if (this.state.searchValue) {
      this.setState({
        hasSearched: true,
        searchMatches: Object.keys(this.allGuests).filter((x) => x.toLowerCase().includes(this.state.searchValue.toLowerCase()))
      })
    }
  }


  handleSubmit = (e) => {
    window.scrollTo({
      top: 100,
      left: 0,
      behavior: 'smooth'
    });
    if (this.state.posting) {
      return
    }
    this.setState({posting: true, hasSubmitted: true})
    e.preventDefault()
    fetch('https://0bcdzd4w3j.execute-api.us-east-2.amazonaws.com/dev/rsvps', {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({guests: this.state.guests})
    }).then(() => {
      this.setState({
        hasSubmitted: true,
        error: false,
        posting: false,
      })
    }).catch(() => {
      this.setState({
        hasSubmitted: true,
        error: true,
        posting: false,
      })
    })
  }

  handleChange = (index, name, value) => {
    const newGuest = {
      ...this.state.guests[index],
    }
    newGuest[name] = value
    this.setState({guests: [...this.state.guests.slice(0, index), newGuest, ...this.state.guests.slice(index + 1, this.state.guests.length)]})
  }

  handleSelectGuest = (name) => {
    this.setState({
      guests: [{name: name, attending: '', dietary: ''}, ...this.allGuests[name].map(g => {return {name: g, attending: '', dietary: ''}})]
    })
  }

  render() {
    return (
      <Layout hero={<Hero img={HeroImageHr} className="desktop-only" />}>
        <Hero className="mobile-only" img={HeroImage} />
        <div className='rsvp-page flex flex-col items-center pt-6 md:pt-3 pb-6 px-3'>
        <div className="w-full text-center" id="result">
          {this.state.hasSubmitted && this.state.posting &&
            <>
              <Loader><h1>Saving your response</h1></Loader>
              <div className="pt-6"/>
            </>
          }
          {this.state.hasSubmitted && !this.state.error && !this.state.posting &&
            <>
            <h1 className="mb-1" >You're all set!</h1>
            <p className="mb-6">If you need to change your response, just submit the form again.</p>
            </>
          }
          {this.state.hasSubmitted && this.state.error && !this.state.posting &&
            <>
            <h1 className="mb-1">Sorry, something went wrong!</h1>
            <p className="mb-6">Please try again later, or contact us directly.</p>
            </>
          }
        </div>
          {this.state.guests.length ? (
            <>
              <form className="guest-form" onSubmit={this.handleSubmit}>
                <div className="w-full">
                  {this.state.guests.map((g, i) => {
                    return <FormSection index={i} guest={g} handleChange={this.handleChange}/>
                  })}
                </div>
                <button disabled={this.state.posting} onClick={this.handleSubmit} className="submit-rsvp-button m-auto block">Submit</button>
              </form>
            </>
            ) : (
              <div className="w-full">
              <div className="text-center mb-6 py-2">
                <p className="mb-3">Please enter your first and/or last name to access your RSVP form.</p>
                <p className="mb-3">If you're responding for you and a guest (or your family), you'll be able to RSVP for your entire group.</p>
                <p>Please respond by September 14th.</p>
              </div>
                <form className='guest-search-form m-auto' onSubmit={this.handleSearch}>
                  <div className="flex items-end">
                    <div className="fancy-input flex-grow">
                      <label htmlFor="search">
                        <div className="label-text">
                          Your Name
                        </div>
                        <input type="text" autoComplete="off" id="search" value={this.state.searchValue} onChange={(e) => this.handleChangeSearch(e.target.value)} />
                      </label>
                    </div>
                    <button className="search-guests" type="submit">Continue</button>
                  </div>
                </form>
                { this.state.searchMatches.length === 0  && this.state.hasSearched &&
                  <div className="guest-list w-full">
                    Hm... we can't find your name. Make sure you enter your name exactly as it appears on your invitation.
                  </div>
                }
                { this.state.searchMatches.length > 0 &&
                  <div className="guest-list w-full">
                    {this.state.searchMatches.map((match, i) => {
                      return (
                        <div className="guest-list-item flex items-center justify-between" key={i}>
                          <span>{match}</span>
                          <button className="select-guest-button" onClick={() => this.handleSelectGuest(match)}>Select</button>
                        </div>
                      )
                    })}
                  </div>
                }
              </div>
            )
          }
        </div>
      </Layout>
    )
  }
}
